import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import {
  useDealerships,
  useAdministrator,
  useGroup,
  useUser,
} from "@/services";

// Interfaces
import {
  IDealerships,
  IResponseListDealerships,
} from "@core/services/interfaces/business/dealerships/IDealershipsService";
import {
  IUser,
  IResponseUpdateUser,
} from "@core/services/interfaces/user/IUserService";
import {
  IResponseListGroup,
  TDataGroup,
} from "@core/services/interfaces/business/group/IGroupService";
import { IOption } from "@core/services/interfaces/IUtil";
import { IResponseGetProfiles } from "@core/services/interfaces/administrator/IAdministratorService";

export default function useProfile(ctx: any) {
  /**
   * Preenchendo select com os grupos econômicos
   * @returns Promise<void>
   */
  const fetchListGroups = async (): Promise<
    Array<{ value: number; label: string }>
  > => {
    const option = await useGroup
      .requestListGroup({
        draw: 1,
        length: 10000,
      })
      .then((response: IResponseListGroup) => {
        const data: Array<{ value: number; label: string }> =
          response.data.data.map((group: TDataGroup) => ({
            value: group.Id,
            label: group.Nome,
          }));

        return data;
      })
      .catch(() => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de Grupos Empresariais!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return [];
      });

    return option;
  };

  const fetchListProfile = async (): Promise<IOption[]> => {
    const option = useAdministrator
      .requestGetProfiles()
      .then((response: IResponseGetProfiles) => {
        const data: Array<{ value: string; label: string }> =
          response.data.data.map((profile) => ({
            value: profile.id,
            label: profile.nome,
          }));

        return data;
      })
      .catch(() => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de Perfis!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return [];
      });

    return option;
  };

  const fetchListDealerships = async (): Promise<
    Array<{ value: number; label: string }>
  > => {
    const amountPerRequest = 5000;
    let requests = [];
    let total = 0;
    let success = true;
    let businessData: IDealerships[] = [];

    await useDealerships
      .requestList({
        paginate: true,
        draw: 1,
        length: amountPerRequest,
      })
      .then((response: IResponseListDealerships) => {
        total = response.data.Data.recordsTotal;
        businessData = response.data.Data.data;
      })
      .catch(() => {
        success = false;
      });

    const pages = Math.ceil(total / amountPerRequest);
    for (let page = 2; page <= pages; page++) {
      requests.push(
        useDealerships.requestList({
          paginate: true,
          draw: page,
          length: amountPerRequest,
        })
      );
    }

    await Promise.all(requests)
      .then((response: IResponseListDealerships[]) => {
        businessData = response.reduce(
          (
            prevValue: IDealerships[],
            currentValue: IResponseListDealerships
          ) => {
            return prevValue.concat(currentValue.data.Data.data);
          },
          businessData
        );
      })
      .catch(() => {
        success = false;
      });

    const option = businessData.map((dealerships: IDealerships) => ({
      value: dealerships.Id,
      label: dealerships.NomeFantasia,
    }));

    return option;
  };

  const fetchGetLoggedUser = async (): Promise<IUser> => {
    const option = await useUser
      .requestGetLoggedUser()
      .then((response: any) => {
        return response.data.Data;
      })
      .catch(() => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar dados do usuário!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return [];
      });

    return option;
  };

  /**
   * Dispara a ação de atualizar Usuário
   */
  const fetchUpdateUser = async (profile: any): Promise<void> => {
    await ctx.$store
      .dispatch("app-user/fetchUpdateUser", {
        id: profile.id,
        perfilId: profile.profile.value,
        email: profile.email,
        nome: profile.name,
        receberBoletimDiario: profile.receiveDailyReport,
      })
      .then((response: IResponseUpdateUser) => {
        if (response.status == 200 || response.status == 201) {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Usuário atualizado com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          ctx.$emit("refresh-data");
        } else {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Ocorreu um erro inesperado!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      })
      .catch(() => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao atualizar usuário!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchListProfile,
    fetchListGroups,
    fetchListDealerships,
    fetchGetLoggedUser,
    fetchUpdateUser,
  };
}
